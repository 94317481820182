import PropTypes from "prop-types";
import Head from "next/head";

PageHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  faviconSrc: PropTypes.string,
};

PageHead.defaultProps = {
  title: "Shop Story",
  description: "",
  keywords:
    "tienda online, tienda en internet, tienda digital, tienda en internet argentina, diseñá tu tienda, cobros online, pagos por web, colaboración, trabajo remoto, mercadopago, todopago, modo pagos",
  faviconSrc: "/icons/favicon.ico",
};

// Adds attributes to the page's head section
export default function PageHead({ title, description, keywords, faviconSrc }) {
  return (
    <Head>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="language" content="Spanish" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://shopstory.io/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://shopstory.io/social_image.jpg"
      />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://shopstory.io/" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta
        property="twitter:image"
        content="https://shopstory.io/social_image.jpg"
      />

      {/* Favicons */}
      <link rel="icon" href={faviconSrc} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/icons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/icons/favicon-16x16.png"
      />
      <link
        rel="mask-icon"
        href="/icons/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <link rel="manifest" href="/manifest.json" />
      <meta name="application-name" content="Shop Story" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Shop Story" />
      <meta name="msapplication-TileColor" content="#ee3465" />
      <meta name="theme-color" content="#ffffff" />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600&family=Inter:wght@400;500;600;700&family=Poppins:wght@400;500;600&family=Tenor+Sans&family=Roboto+Slab:wght@400;500;600&family=Source+Code+Pro:wght@400;500;600&family=DM+Sans:ital,wght@0,500;0,700;1,400&family=Poppins:wght@400;500;600&display=swap"
        rel="stylesheet"
      />

      {/* InstantSearch CSS */}
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css"
        integrity="sha256-t2ATOGCtAIZNnzER679jwcFcKYfLlw01gli6F6oszk8="
        crossOrigin="anonymous"
      />

      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Head>
  );
}
