import {
  Box,
  VStack,
  HStack,
  Flex,
  DarkMode,
  Button,
  Divider,
  IconButton,
  Text,
} from "@chakra-ui/react";
import Logo from "~/components/logo";
import { Paragraph02, Paragraph03, Paragraph04 } from "~/components/Texts";
import {
  FiTwitter,
  FiFacebook,
  FiLinkedin,
  FiInstagram,
  FiMail,
} from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";
import Link from "next/link";

export default function Footer({}) {
  return (
    <HStack
      w="100%"
      backgroundColor="light.brand.strong"
      p="4"
      color="light.background.superLite"
      justify="center"
    >
      <DarkMode>
        <VStack w="100%" maxW="1400px">
          {/* Top row */}
          <Flex
            direction={{ mobile: "column", desktop: "row" }}
            w="100%"
            flex="1"
            pt={{ mobile: "2", desktop: "8" }}
            pb="6"
            align="flex-start"
          >
            {/* Logo panel*/}
            <VStack
              h={{ mobile: "auto", desktop: "100%" }}
              w={{ mobile: "100%", desktop: "auto" }}
              spacing="4"
              pb={{ mobile: "6", desktop: "0" }}
              align="flex-start"
            >
              <VStack w="100%" align="flex-start">
                <Box pb="2">
                  <Link href="/">
                    <Button variant="unstyled" role="Volver al inicio">
                      <Logo />
                    </Button>
                  </Link>
                </Box>
                <Box maxW={{ mobile: undefined, desktop: "300px" }}>
                  <Paragraph02>
                    Las historias de éxito comienzan con Shop Story.
                  </Paragraph02>
                </Box>
              </VStack>
              <HStack
                flex="1"
                h="100%"
                spacing="4"
                align="flex-end"
                pt={{ mobile: "0", desktop: "4" }}
              >
                <a
                  href="https://www.instagram.com/shopstory.io/"
                  target="_blank"
                  rel="noopener"
                >
                  <IconButton
                    variant="ghost"
                    icon={<FiInstagram />}
                    colorScheme="white"
                    borderRadius="full"
                    size="lg"
                  />
                </a>
                <a
                  href="https://twitter.com/shopstoryio"
                  target="_blank"
                  rel="noopener"
                >
                  <IconButton
                    variant="ghost"
                    icon={<FiTwitter />}
                    colorScheme="white"
                    borderRadius="full"
                    size="lg"
                  />
                </a>
                <a
                  href="https://www.facebook.com/shopstory.io"
                  target="_blank"
                  rel="noopener"
                >
                  <IconButton
                    variant="ghost"
                    icon={<FiFacebook />}
                    colorScheme="white"
                    borderRadius="full"
                    size="lg"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/shopstory.io/"
                  target="_blank"
                  rel="noopener"
                >
                  <IconButton
                    variant="ghost"
                    icon={<FiLinkedin />}
                    colorScheme="white"
                    borderRadius="full"
                    size="lg"
                  />
                </a>
              </HStack>
            </VStack>

            <HStack
              flex="1"
              w="100%"
              spacing={{ mobile: "6", desktop: "12" }}
              align="flex-start"
              justify={{ mobile: "flex-start", desktop: "flex-end" }}
            >
              {/* Links */}
              <VStack spacing={{ base: "4" }} align="flex-start">
                <Paragraph02>
                  <b>Información</b>
                </Paragraph02>
                <Link href="/pricing">
                  <Button
                    variant="link"
                    color="ligth.background.superLite"
                    colorScheme="secondary"
                  >
                    Precios
                  </Button>
                </Link>
                <Link href="/support">
                  <Button
                    variant="link"
                    color="ligth.background.superLite"
                    colorScheme="secondary"
                  >
                    Ayuda
                  </Button>
                </Link>
                <a
                  href="https://shopstory.peerboard.com"
                  target="_blank"
                  rel="noopener"
                >
                  <Button
                    variant="link"
                    color="ligth.background.superLite"
                    colorScheme="secondary"
                  >
                    Comunidad
                  </Button>
                </a>
              </VStack>

              {/* Contact */}
              <VStack spacing={{ base: "2", md: "4" }} align="flex-start">
                <Paragraph02>
                  <b>Contacto</b>
                </Paragraph02>
                <Box maxW="250px">
                  <Text textStyle={{ base: "paragraph04", md: "paragraph03" }}>
                    Dudas, consultas, ayuda con tu tienda o lo que necesites.
                  </Text>
                </Box>
                <a
                  href="mailto:ayuda@shopstory.io"
                  target="_blank"
                  rel="noopener"
                >
                  <HStack>
                    <FiMail />
                    <Paragraph03>ayuda@shopstory.io</Paragraph03>
                  </HStack>
                </a>
                <a
                  href="https://wa.me/message/7G7JOHQIHHDLI1"
                  target="_blank"
                  rel="noopener"
                >
                  <HStack>
                    <IoLogoWhatsapp />
                    <Paragraph03>(+54) 11-3985-7289</Paragraph03>
                  </HStack>
                </a>
              </VStack>
            </HStack>
          </Flex>

          <Divider w="100%" />

          <Flex
            direction={{ mobile: "column-reverse", desktop: "row" }}
            w="100%"
          >
            <Paragraph04 pt={{ mobile: "2", desktop: "0" }}>
              Shop Story © 2021 shopstory.io
            </Paragraph04>
            <Box flex="1" />
            <HStack spacing="4">
              <Link href="/legal/terms-and-conditions">
                <Button variant="link" colorScheme="white" size="sm">
                  Términos y condiciones
                </Button>
              </Link>
              <Link href="/legal/privacy-policy">
                <Button variant="link" colorScheme="white" size="sm">
                  Política de privacidad
                </Button>
              </Link>
            </HStack>
          </Flex>
        </VStack>
      </DarkMode>
    </HStack>
  );
}
