import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { Box, chakra, Wrap, WrapItem } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";

AnimatedEntryOnViewport.propTypes = {
  animated: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  textStyle: PropTypes.string,
  align: PropTypes.string,
};

AnimatedEntryOnViewport.defaultProps = {
  animated: true,
  width: "auto",
  height: "auto",
  textStyle: "display02",
  align: "flex-start",
};

// Source: https://codesandbox.io/s/framer-motion-split-text-o1r7d
export default function AnimatedEntryOnViewport({
  animated,
  children,
  width,
  height,
  textStyle,
  align,
}) {
  const [shown, setShown] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true, // Optimization, stop trackign after the first appearence
    threshold: 0.1, // 10% In view to trigger
  });

  useEffect(() => {
    if (inView && !shown) setShown(true);
  }, [inView]);

  if (!animated)
    return (
      <chakra.h2 textStyle={textStyle} textAlign="center">
        {children}
      </chakra.h2>
    );

  return (
    <Box width={width} height={height} ref={ref}>
      <AnimatePresence>
        {(inView || shown) && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <SplitText
              initial={{ y: "100%" }}
              animate="visible"
              variants={{
                visible: (i) => ({
                  y: 0,
                  transition: {
                    delay: i * 0.1,
                  },
                }),
              }}
              textStyle={textStyle}
              align={align}
            >
              {children}
            </SplitText>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}

const SplitText = ({ children, textStyle, align, ...rest }) => {
  let words = children.split(" ");

  return (
    <Wrap spacing="0" justify={align} align={align}>
      {words.map((word, i) => (
        <WrapItem
          key={children + i}
          style={{ display: "inline-block", overflow: "hidden" }}
        >
          <motion.div
            {...rest}
            style={{ display: "inline-block", willChange: "transform" }}
            custom={i}
          >
            <chakra.h2 textStyle={textStyle}>
              {word + (i !== words.length - 1 ? "\u00A0" : "")}
            </chakra.h2>
          </motion.div>
        </WrapItem>
      ))}
    </Wrap>
  );
};
