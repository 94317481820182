import { useRef } from "react";
import {
  Box,
  Flex,
  VStack,
  HStack,
  DarkMode,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  useDisclosure,
  LightMode,
  Divider,
  Stack,
  Text,
  Avatar,
} from "@chakra-ui/react";
import Logo from "~/components/logo";
import Link from "next/link";
import { AiOutlineShop } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import { Mobile, Desktop } from "~/components/MediaQuery";
import { FiTwitter, FiFacebook, FiLinkedin, FiInstagram } from "react-icons/fi";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import { useUser } from "~/context/userContext";

export default function Header() {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      w="100%"
      maxW="100vw"
      zIndex="docked"
      px={{ base: "0", md: "6", lg: "14", xl: "16" }}
    >
      <AnimatedEntryOnViewport animation="slideFromTop">
        <HStack
          w="100%"
          py="2"
          pl={{ base: "0", md: "2" }}
          pr="4"
          backgroundColor="light.brand.strong"
          borderBottomRadius="brand"
          boxShadow="lg"
          spacing="4"
        >
          <Box pb="2">
            <DarkMode>
              <Link href="/">
                <Button variant="unstyled" role="Volver al inicio">
                  <Logo height="42px" />
                </Button>
              </Link>
            </DarkMode>
          </Box>

          <Desktop w="100%">
            <HStack w="100%" justify="space-between" spacing="8">
              <NavigationLinks />
              <MainActions />
            </HStack>
          </Desktop>

          <Mobile w="100%">
            <HStack w="100%" justify="flex-end">
              <MobileDrawer />
            </HStack>
          </Mobile>
        </HStack>
      </AnimatedEntryOnViewport>
    </Box>
  );
}

const MainActions = ({ direction = "row", spacing = "4" }) => {
  const { user, loadingUser } = useUser();

  // Validate that the user isn't anonymous
  const userIsLoggedIn = () => !loadingUser && user && user.email !== null;

  return (
    <Flex direction={direction}>
      {!userIsLoggedIn() && (
        <DarkMode>
          <Link href="/auth/login">
            <Button variant="outline" colorScheme="secondary" color="white">
              Iniciar sesión
            </Button>
          </Link>
        </DarkMode>
      )}
      {!userIsLoggedIn() && (
        <>
          <Box boxSize={spacing} />
          <Link href="/auth/signup">
            <Button rightIcon={<AiOutlineShop />}>Creá tu tienda</Button>
          </Link>
        </>
      )}
      {userIsLoggedIn() && (
        <Link href="/my-shops">
          <Button colorScheme="primary" color="white">
            <HStack>
              <Text>Mis tiendas</Text>
              <Avatar
                src={user.photoURL}
                name={user.displayName}
                size="xs"
                borderWidth="1px"
                borderColor="white"
              />
            </HStack>
          </Button>
        </Link>
      )}
    </Flex>
  );
};

const NavigationLinks = ({ direction = "row" }) => {
  return (
    <DarkMode>
      <Stack
        direction={direction}
        align="flex-start"
        spacing={{ base: "0", xl: "2" }}
      >
        <Link href="/pricing">
          <Button color="white" colorScheme="secondary" variant="ghost">
            Precios
          </Button>
        </Link>
        <Link href="/support">
          <Button color="white" colorScheme="secondary" variant="ghost">
            Ayuda
          </Button>
        </Link>
        <a
          href="https://shopstory.peerboard.com"
          target="_blank"
          rel="noopener"
        >
          <Button color="white" colorScheme="secondary" variant="ghost">
            Comunidad
          </Button>
        </a>
      </Stack>
    </DarkMode>
  );
};

const Social = () => {
  return (
    <HStack spacing="4" align="flex-end" pt={{ mobile: "0", desktop: "4" }}>
      <IconButton
        variant="ghost"
        icon={<FiInstagram />}
        colorScheme="white"
        borderRadius="full"
        size="lg"
      />
      <IconButton
        variant="ghost"
        icon={<FiTwitter />}
        colorScheme="white"
        borderRadius="full"
        size="lg"
      />
      <IconButton
        variant="ghost"
        icon={<FiFacebook />}
        colorScheme="white"
        borderRadius="full"
        size="lg"
      />
      <IconButton
        variant="ghost"
        icon={<FiLinkedin />}
        colorScheme="white"
        borderRadius="full"
        size="lg"
      />
    </HStack>
  );
};

const MobileDrawer = ({}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <>
      <IconButton
        ref={btnRef}
        colorScheme="white"
        color="white"
        onClick={onOpen}
        icon={<FiMenu />}
        variant="outline"
      />
      <Drawer
        isOpen={isOpen}
        size="xs"
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor="light.brand.strong">
          <LightMode>
            <DrawerCloseButton color="light.background.superLite" />
          </LightMode>
          <DrawerHeader color="light.background.superLite">
            Navegación
          </DrawerHeader>

          <DrawerBody>
            <VStack w="100%" spacing="4" align="flex-start">
              <MainActions direction="column" />
              <Divider />
              <NavigationLinks direction="column" />
            </VStack>
          </DrawerBody>

          <DrawerFooter>
            <HStack w="100%" justify="center">
              <Social />
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
