import { Box, VStack, Button, Text, Stack } from "@chakra-ui/react";
import { Label01, Paragraph01 } from "~/components/Texts";
import { FiArrowRight } from "react-icons/fi";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import Link from "next/link";
import AnimatedTextEntryOnViewport from "~/components/animations/AnimatedTextEntryOnViewport";
import Img from "react-optimized-image";
import MockupImage from "~/public/marketing/landing_cta_mockups.png";

export default function LandingSectionLargeCallToAction() {
  return (
    <Stack
      direction={{ base: "column-reverse", md: "row" }}
      align="flex-end"
      w="100%"
      pt={{ mobile: "4", desktop: "20" }}
      color="light.brand.strong"
    >
      <Box
        flex={{ base: undefined, md: "1" }}
        w="100%"
        h={{ base: "300px", md: "600px", lg: "800px" }}
      >
        <AnimatedEntryOnViewport animation="slideFromLeft" delay={0.5}>
          <Img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "0% 100%",
            }}
            src={MockupImage}
            webp
            sizes={[300, 600, 800]}
            densities={[1, 2]} // Retina support
            draggable="false"
            loading="lazy"
            alt="Tienda online disponible en computadoras, tablets y dispositivos móviles"
          />
        </AnimatedEntryOnViewport>
      </Box>

      <Box flex="1" w="100%">
        <AnimatedEntryOnViewport>
          <VStack
            flex="1"
            h="100%"
            py={{ mobile: "8", desktop: "20" }}
            px={{ mobile: "4", desktop: "8" }}
            align="flex-start"
            justify="center"
            spacing="8"
          >
            <VStack align="flex-start" maxW="500px">
              <Label01>Empezá ahora</Label01>
              <AnimatedTextEntryOnViewport textStyle="display02">
                Las historias de éxito comienzan con Shop Story
              </AnimatedTextEntryOnViewport>
              <Paragraph01 color="light.brand.lite">
                <b>¡Sí, tu idea es genial!,</b> hacela realidad ahora y comenzá
                tu camino emprendedor de la mejor manera. <br />
                #HacelaFácil.
              </Paragraph01>
            </VStack>
            <VStack align="flex-start" spacing="4">
              <Link href="/auth/signup">
                <Button
                  size="lg"
                  colorScheme="secondary"
                  rightIcon={<FiArrowRight />}
                >
                  Creá tu tienda ahora
                </Button>
              </Link>
              <Link href="/pricing">
                <a>
                  <Text wordBreak="break-word" textAlign="left">
                    <b>Comenzá gratis, sólo pagas después de vender</b>
                  </Text>
                </a>
              </Link>
            </VStack>
          </VStack>
        </AnimatedEntryOnViewport>
      </Box>
    </Stack>
  );
}
