import PropTypes from "prop-types";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

AnimatedEntryOnViewport.propTypes = {
  animated: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  duration: PropTypes.number,
  delay: PropTypes.number,
  animation: PropTypes.oneOf([
    "slideFromBottom",
    "slideFromTop",
    "slideFromLeft",
    "slideFromRight",
    "popIn",
  ]),
};

AnimatedEntryOnViewport.defaultProps = {
  animated: true,
  width: "100%",
  height: "100%",
  duration: 0.3,
  delay: 0.2,
  animation: "slideFromBottom",
};

export default function AnimatedEntryOnViewport({
  animated = true,
  children,
  width,
  height,
  duration,
  delay,
  animation,
}) {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true, // Optimization, stop trackign after the first appearence
    threshold: 0.1, // 10% In view to trigger
  });

  const initialX = () => {
    switch (animation) {
      case "slideFromLeft":
        return "-10%";
      case "slideFromRight":
        return "10%";
      default:
        return 0;
    }
  };

  const initialY = () => {
    switch (animation) {
      case "slideFromBottom":
        return "10%";
      case "slideFromTop":
        return "-10%";
      default:
        return 0;
    }
  };

  const initialScale = () => {
    switch (animation) {
      case "popIn":
        return 0;
      default:
        return 1;
    }
  };

  const transitionEase = () => {
    switch (animation) {
      case "popIn":
        return "anticipate";
      default:
        return "easeOut";
    }
  };

  const variants = {
    visible: { opacity: 1, y: 0, x: 0, scale: 1 },
    hidden: { opacity: 0, x: initialX(), y: initialY(), scale: initialScale() },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  if (!animated) return children;

  return (
    <motion.div
      ref={ref}
      style={{
        width: width,
        height: height,
      }}
      animate={controls}
      initial={animated ? "hidden" : "visible"}
      transition={{ duration: duration, delay: delay, ease: transitionEase() }}
      variants={variants}
    >
      {children}
    </motion.div>
  );
}
