import { Box } from "@chakra-ui/react";
import Breakpoints from "~/theme/foundations/breakpoints";

// The following componenets will make their childs visible only when displayed on the appropiate device type
// Please note that these compenents return a custom Cahkra's Box componenet.
// Any prop passed to any of these componenets will be passed to the underlaying Box componenet, making customization a breeze.

// IMPORTANT: Children are hidden using the CSS Display property. Children will be hidden visually, but will be executed by React.
// This is done this way so SSR always include the children components in the generated HTML/CSS that gets served before the hydratation takes place.

// Displays the children components ONLY IN DESKTOP DEVICES.
export function Desktop(props) {
  return <Box {...props} display={{ mobile: "none", desktop: "block" }} />;
}

// Displays the children components ONLY IN MOBILE DEVICES.
export function Mobile(props) {
  return <Box {...props} display={{ mobile: "block", desktop: "none" }} />;
}

export function isDesktop() {
  return window.matchMedia(`(min-width: ${Breakpoints.desktop})`).matches;
}

export function isMobile() {
  return window.matchMedia(`(max-width: ${Breakpoints.desktop})`).matches;
}
