import PageHead from "~/components/PageHead";
import AuthLock from "~/components/AuthLock";

/* 
Base Page component.
This component is intended to be used as the top lavel comoponent of every page.
What this component does:

1) Added a header to the page, with some necessary imports or certain things like analytics trackers.
It also exposes an easy way of changing the page title.

2) Exposes an easy way of locking pages for authenticed users only. It achieves this behaviour by using 
the AuthLock component.
*/

export default function Page(props) {
  const {
    title = "Shop Story",
    description,
    allowLoggedUsersOnly = false,
  } = props;

  return (
    <div>
      <PageHead title={title} description={description} />
      <AuthLock allowsAll={!allowLoggedUsersOnly}>{props.children}</AuthLock>
    </div>
  );
}
