import { useEffect } from "react";
import { useUser } from "~/context/userContext";
import { useRouter } from "next/router";

// Prevents unlogged users from accesing the childrens of this component
//
// Scenarios:
//  A) If the user is not logged in at the moment of accessing this component,
// they will be redirected to the ON_NON_AUTHENTICATED_REDIRECT route
//
// B) If the user is logged and accesing the children componentes, but at any given time they get logged out,
// they will be redirected to the ON_NON_AUTHENTICATED_REDIRECT route

const ON_NON_AUTHENTICATED_REDIRECT = "/auth/login";

export default function AuthLock(props) {
  const {
    children,
    redirectUrl = ON_NON_AUTHENTICATED_REDIRECT,
    allowsAll = false,
  } = props;
  const { user, loadingUser } = useUser();
  const router = useRouter();

  const isAnonymous = (user) => {
    return user && user.email === null;
  };

  useEffect(() => {
    if (!allowsAll && !loadingUser) {
      if (!user) router.replace(redirectUrl);
      else if (isAnonymous(user)) router.replace(redirectUrl);
    }
  }, [user, loadingUser]);

  // If the AuthLock is disabled render the children
  if (allowsAll) return children;

  // If the AuthLock is enabled, check for authentication
  if (user && !isAnonymous(user)) return children;

  // By default, return nothing
  return null;
}
