import { chakra } from "@chakra-ui/react";

export const Display01 = (props) => (
  <chakra.h1 textStyle="display01" {...props} />
);

export const Display02 = (props) => (
  <chakra.h2 textStyle="display02" {...props} />
);

export const Display03 = (props) => (
  <chakra.h3 textStyle="display03" {...props} />
);

export const Label01 = (props) => <chakra.h4 textStyle="label01" {...props} />;

export const Label02 = (props) => <chakra.h5 textStyle="label02" {...props} />;

export const Button01 = (props) => <chakra.p textStyle="button01" {...props} />;

export const Button02 = (props) => <chakra.p textStyle="button02" {...props} />;

export const Button03 = (props) => <chakra.p textStyle="button03" {...props} />;

export const Paragraph01 = (props) => (
  <chakra.p textStyle="paragraph01" {...props} />
);

export const Paragraph02 = (props) => (
  <chakra.p textStyle="paragraph02" {...props} />
);

export const Paragraph03 = (props) => (
  <chakra.p textStyle="paragraph03" {...props} />
);

export const Paragraph04 = (props) => (
  <chakra.p textStyle="paragraph04" {...props} />
);
